// @ts-check
import { graphql, useStaticQuery } from 'gatsby';

/**
 * @returns
 */
export default function useWines() {
  const data = useStaticQuery(graphql`
    query useWines {
      allSanityProduct(filter: { stand: { ne: null } }) {
        nodes {
          productId: _id
          ...sanity_ProductListRow
          ...sanity_ProductHeader
        }
      }
    }
  `);
  const wines = data.allSanityProduct.nodes;
  return wines.map((wine) => afterQuery(wine));
}

function afterQuery(product) {
  if (!product) return undefined;
  return {
    countryCode: countryCodes[product.countryName],
    producer: { name: product.producerName },
    ...product,
  };
}
const countryCodes = { Deutschland: 'de' };
