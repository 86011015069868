import baseTheme from '@bottlebooks/gatsby-theme-event/src/theme';
import merge from 'lodash/merge';
const theme = {
  ...baseTheme,
  fontUrls: ['https://use.typekit.net/glm8gcd.css'],

  fonts: merge({}, baseTheme.fonts, {
    heading: 'din-2014, sans-serif',
    body: '"EB Garamond", serif',
  }),
  fontWeights: {
    ...baseTheme.fontWeights,
    heading: '600',
  },
  colors: {
    ...baseTheme.colors,
    // Producer labels
    lightestText: '#555',
  },
  title: merge({}, baseTheme.title, {
    _base: {
      WebkitFontSmoothing: 'antialiased',
    }, // Add antialiasing to titles.
  }),

  styles: {
    ...baseTheme.styles,
    root: {
      ...baseTheme.styles.root,
      WebkitFontSmoothing: 'subpixel-antialiased', // Garamond and DIN look bad with antialised in small sizes.
    },
  },
};

// console.log(theme);
export default theme;
