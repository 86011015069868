/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import ExhibitorPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorPage';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';

export default function SingleExhibitorPage({ data, location }) {
  const { exhibitor, products } = afterQuery(data);
  return (
    <ExhibitorPage
      exhibitor={exhibitor}
      products={products}
      location={location}
    />
  );
}

function afterQuery({ sanityExhibitor: exhibitor }) {
  return {
    exhibitor: {
      // Hard-code Germany to get flag
      countryCode: 'de',
      ...exhibitor,
    },
    products: exhibitor?.products?.map((product) => ({
      // Hard-code Germany to get flag
      countryCode: 'de',
      ...product,
      owner: 'me',
      pricing: {
        ...product.pricing,
        priceStrategy: 'BY_PRICE_RANGE', // TODO: remove this once we have the default set correctly.
      },
    })),
  };
}

export const query = graphql`
  query ($id: String) {
    sanityExhibitor(id: { eq: $id }) {
      ...sanity_ExhibitorPage
    }
  }
`;
