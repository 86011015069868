import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`10% Rabatt bei Nachbestellung`}</h3>
    <p>{`Viele Weingüter haben im Nachgang der Virtuellen WeinTour ein besonderes Angebot und gewähren 10 % Nachlass auf die erste Bestellung, die direkt beim Weingut erfolgt. Die teilnehmenden Winzer und Winzerinnen erkennen Sie im Katalog und im Ausstellerprofil auf der Website an diesem pinken Button.`}</p>
    <p>{`Stöbern Sie gleich bei den Ausstellern sowie den 19 interaktiven Online-Verkostungen!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      