/** @jsx jsx */
import { Link } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import { jsx } from 'theme-ui';

/**
 * Implements CallToActionElement
 * @param {object} props
 * @param {string} props.label
 * @param {string} props.url
 * @param {'text' | 'primary' | 'outline' } props.variant
 * @returns
 */
export default function CallToAction({ label, url, variant, ...rest }) {
  if (!label) return null;
  const isExternal = url.includes('http');
  const link = isExternal ? { href: url } : { to: url };
  if (variant === 'text')
    return (
      <Link variant="onPrimary" {...link} {...rest}>
        {label}
      </Link>
    );
  return (
    <Button variant={variant} {...link} {...rest}>
      {label}
    </Button>
  );
}
