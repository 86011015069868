/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check

import {
  CheckboxField,
  Form,
  TextAreaField,
  TextField,
} from '@bottlebooks/form';
import {
  Box,
  Button,
  Col,
  Container,
  Link,
  P,
  SemiTitle,
  Stack,
  Text,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import BrandAddressSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandAddressSection';
import BrandBanner from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandBanner';
import BrandShopsSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandShopsSection';
import MarketingActivitiesSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/MarketingActivitiesSection';
import ContentRow from '@bottlebooks/gatsby-theme-event/src/components/ContentRow';
import ExhibitorActions from '@bottlebooks/gatsby-theme-event/src/components/Exhibitor/ExhibitorActions';
import ExhibitorHeaderRow from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorHeaderRow';
import ExhibitorSections from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorPage/ExhibitorSections';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import { Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { graphql, navigate } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';
import { boolean, object, string } from 'yup';
import Section from '../../../../cms-design-system/Layout/Section';
import PackageListItem from '../../../../components/PackageListItem';
import ProductCard from '../../../../components/ProductCard';

export default function ExhibitorPage({
  eventId,
  exhibitor,
  products,
  previous,
  location,
  next,
  locale,
}) {
  const page = {
    path: location.href,
    title: exhibitor.name,
    image: exhibitor.logo?.fixed?.src || '',
    description: exhibitor.shortDescription,
  };

  return (
    <Layout locale={locale} sx={{ backgroundColor: 'transparent' }} page={page}>
      <BrandBanner {...exhibitor} />
      <ExhibitorHeaderRow
        exhibitor={exhibitor}
        previous={previous}
        next={next}
      />

      <Container>
        <ContentRow sx={{ paddingY: 3 }}>
          <Col>
            <ExhibitorActions exhibitor={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      <MarketingActivitiesSection brand={exhibitor} />

      <ExhibitorSections
        exhibitor={{ ...exhibitor, products }}
        eventId={eventId}
      />

      <Container>
        <ContentRow sx={{ paddingY: 3 }}>
          <Col>
            <WineInPackageSection products={products} />
          </Col>
        </ContentRow>
      </Container>

      <BrandAddressSection brand={exhibitor} />

      <Container>
        <ContentRow>
          <Col>
            <BrandShopsSection brand={exhibitor} />
          </Col>
        </ContentRow>
      </Container>

      <Section variant="dark">
        <Container>
          <ContentRow sx={{ marginY: [4, 5] }}>
            <TourismHighlights exhibitor={exhibitor} />
          </ContentRow>
        </Container>
      </Section>
      <Section>
        <Container>
          <ContentRow sx={{ marginY: [4, 5] }}>
            <ContactForm exhibitor={exhibitor} action={location.pathname} />
          </ContentRow>
        </Container>
      </Section>
    </Layout>
  );
}

function WineInPackageSection({ products, ...rest }) {
  const { i18n } = useLingui();
  const packageProducts = products?.filter((product) =>
    Boolean(product.packageItems?.length)
  );
  if (!packageProducts?.length) return null;
  return (
    <Stack>
      {packageProducts.map((product) =>
        product.packageItems.map((packageItem) => (
          <Stack key={`${product._id}-${packageItem._id}`}>
            <Box>
              <SemiTitle>
                Unser Wein im WineWalk in München und in der Online-Verkostung
                Nr. {packageItem.packageNumber}
              </SemiTitle>
              <ProductCard product={product}>
                <ProductAlcohol product={product} />
                <ProductAnalytics product={product} />
                {product.bottleSize && (
                  <Box>
                    <SemiTitle>Flaschengröße</SemiTitle>
                    <Text>{product.bottleSize}l</Text>
                  </Box>
                )}
              </ProductCard>
            </Box>

            <SemiTitle>Online-Verkostung (50ml Probierschlucke)</SemiTitle>
            <PackageListItem
              packageItem={packageItem}
              sx={{ marginTop: 'auto' }}
            />
          </Stack>
        ))
      )}
    </Stack>
  );
}

const analyticFormat = {
  minimumFractionDigits: 1,
  maximumFractionDigits: 2,
};

function ProductAlcohol({ product, ...rest }) {
  const { i18n } = useLingui();
  if (product.alcohol == null) return null;
  return (
    <Box {...rest}>
      <SemiTitle>
        <Trans>Alcohol</Trans>
      </SemiTitle>
      <P variant="title">{i18n.number(product.alcohol, analyticFormat)}%</P>
    </Box>
  );
}

function ProductAnalytics({ product, ...rest }) {
  const { i18n } = useLingui();
  const { residualSugar, totalAcidity, designation, pH } = product;
  if (
    residualSugar == null &&
    totalAcidity == null &&
    designation == null &&
    pH == null
  ) {
    return null;
  }
  return (
    <Box>
      <SemiTitle>
        <Trans>Analytical data</Trans>
      </SemiTitle>
      {designation && <P variant="title">{designation}</P>}
      {residualSugar != null && (
        <Text>
          <Trans>
            {i18n.number(residualSugar, analyticFormat)} g/l residual sugar
          </Trans>
        </Text>
      )}
      {totalAcidity != null && (
        <Text>
          <Trans>{i18n.number(totalAcidity, analyticFormat)} g/l acidity</Trans>
        </Text>
      )}
      {pH != null && (
        <Text>
          <Trans>{i18n.number(pH, analyticFormat)} pH</Trans>
        </Text>
      )}
    </Box>
  );
}

function TourismHighlights({ exhibitor }) {
  if (!exhibitor.tourismHighlights?.length) return null;
  return (
    <>
      <Title sx={{ marginBottom: 4 }}>
        Unsere Tipps in der Region {exhibitor.region}
      </Title>
      <Stack spacing={4}>
        {exhibitor.tourismHighlights.map((highlight) => (
          <Link key={highlight._key} href={highlight.url} openInNewTab>
            <Stack spacing={1}>
              <Title variant="small">{highlight.name}</Title>
              <P sx={{ color: 'text' }}>{highlight.description}</P>
              <Text>{highlight.url}</Text>
            </Stack>
          </Link>
        ))}
      </Stack>
    </>
  );
}

const schema = object().shape({
  name: string().required('Das ist ein Pflichtfeld'),
  email: string()
    .email('Bitte eine gültige E-Mail-Adresse eingeben.')
    .required('Das ist ein Pflichtfeld'),
  subject: string(),
  message: string().required('Das ist ein Pflichtfeld'),
  acceptPrivacy: boolean().oneOf(
    [true],
    'Bitte die Datenschutzbestimmungen akzeptieren.'
  ),
});

function ContactForm({ exhibitor, action }) {
  /** @type {[string | undefined, React.Dispatch<React.SetStateAction<string | undefined>>]} */
  const [serverError, setServerError] = React.useState(undefined);
  const formName = 'exhibitor-contact';
  /** @type {Parameters<Form>[0]["onSubmit"]} */
  async function handleSubmit(values, { setSubmitting, setErrors }) {
    try {
      const response = await fetch(action, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: new URLSearchParams({
          'form-name': formName,
          ...values,
        }).toString(),
      });
      setSubmitting(false);
      if (!response.ok) {
        setServerError(response.statusText);
        setSubmitting(false);
        return;
      }

      navigate(`/danke`);
    } catch (error) {
      setSubmitting(false);
      setServerError(error.message);
    }
  }

  return (
    <Box>
      <Title sx={{ marginBottom: 4 }}>
        <Trans>Direkter Kontakt</Trans>
      </Title>
      <Text>Sie haben Fragen, möchten einen Telefon- oder Chat-Termin?</Text>
      <Text>Schreiben Sie {exhibitor.name} eine Nachricht!</Text>
      <Form
        name={formName}
        initialValues={{
          name: '',
          email: '',
          subject: '',
          message: '',
          acceptPrivacy: false,
          to: exhibitor.email,
          exhibitorName: exhibitor.name,
        }}
        onSubmit={handleSubmit}
        validationSchema={schema}
        sx={{ paddingY: 4 }}
        data-netlify="true"
        netlify-honeypot="bot-field"
        // data-netlify-recaptcha="true"
      >
        <input type="hidden" name="form-name" value={formName} />
        <input type="hidden" name="exhibitor" value={exhibitor.name} />
        <input type="hidden" name="to" value={exhibitor.email} />
        <label sx={{ display: 'none' }}>
          Don't fill this out if you're human: <input name="bot-field" />
        </label>
        <TextField name="name" label="Ihr Name" length={50} />
        <TextField name="email" label="Ihre E-Mail-Adresse" length={40} />
        <TextAreaField name="message" label="Ihre Nachricht" rows={5} />
        <CheckboxField
          name="acceptPrivacy"
          label="Ich akzeptiere die Datenschutzbestimmungen der Seite zur Bearbeitung meiner Anfrage"
        />
        <div data-netlify-recaptcha="true" />
        {serverError && (
          <Box sx={{ marginY: 2 }}>
            <Text sx={{ color: 'brand.warningRed' }}>
              Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.
            </Text>
            <Text variant="small" sx={{ color: 'brand.warningRed' }}>
              {serverError}
            </Text>
          </Box>
        )}
        <Button type="submit" variant="primary">
          Senden
        </Button>
      </Form>
    </Box>
  );
}

export const fragment = graphql`
  fragment TourismHighlights on SanityExhibitor {
    tourismHighlights {
      _key
      name
      description
      url
    }
  }
  fragment sanity_BrandHeader on SanityExhibitor {
    mainImage {
      ...ImageWithPreview
    }
    logo {
      ...ImageWithPreview
    }
    stand {
      title: name
    }
    name
    country
    region
  }

  fragment WineInPackageSection on SanityExhibitor {
    products {
      _id
      bottleSize
      designation
      packageItems {
        _id
        packageNumber
        name
        ...PackageListItem
      }
    }
  }

  fragment sanity_BrandAddressSection on SanityExhibitor {
    city
    postalCode
    street
    telephone
    email
    website
    latitude
    longitude
  }

  fragment ContactForm on SanityExhibitor {
    # owner # TODO this is missing
    name
    email
  }

  fragment sanity_ExhibitorPage on SanityExhibitor {
    slug {
      current
    }
    ...sanity_BrandHeader
    ...sanity_BrandDetailsSections
    ...sanity_BrandAddressSection
    ...TourismHighlights
    ...WineInPackageSection
    ...ContactForm

    products {
      ...WineListItem
      onlineShop
    }
  }
`;
