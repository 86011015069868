// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-aussteller-js": () => import("./../../../src/pages/aussteller.js" /* webpackChunkName: "component---src-pages-aussteller-js" */),
  "component---src-pages-aussteller-sanity-exhibitor-slug-current-js": () => import("./../../../src/pages/aussteller/{SanityExhibitor.slug__current}.js" /* webpackChunkName: "component---src-pages-aussteller-sanity-exhibitor-slug-current-js" */),
  "component---src-pages-danke-js": () => import("./../../../src/pages/danke.js" /* webpackChunkName: "component---src-pages-danke-js" */),
  "component---src-pages-map-js": () => import("./../../../src/pages/map.js" /* webpackChunkName: "component---src-pages-map-js" */),
  "component---src-pages-packages-js": () => import("./../../../src/pages/packages.js" /* webpackChunkName: "component---src-pages-packages-js" */),
  "component---src-pages-packages-sanity-package-item-slug-current-js": () => import("./../../../src/pages/packages/{SanityPackageItem.slug__current}.js" /* webpackChunkName: "component---src-pages-packages-sanity-package-item-slug-current-js" */),
  "component---src-pages-preview-page-id-js": () => import("./../../../src/pages/preview/[pageId].js" /* webpackChunkName: "component---src-pages-preview-page-id-js" */),
  "component---src-pages-regionen-[region]-js": () => import("./../../../src/pages/regionen/[region].js" /* webpackChunkName: "component---src-pages-regionen-[region]-js" */),
  "component---src-pages-regionen-index-js": () => import("./../../../src/pages/regionen/index.js" /* webpackChunkName: "component---src-pages-regionen-index-js" */),
  "component---src-pages-sanity-page-slug-current-js": () => import("./../../../src/pages/{SanityPage.slug__current}.js" /* webpackChunkName: "component---src-pages-sanity-page-slug-current-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-speakers-index-js": () => import("./../../../src/pages/speakers/index.js" /* webpackChunkName: "component---src-pages-speakers-index-js" */),
  "component---src-pages-speakers-sanity-speaker-slug-current-js": () => import("./../../../src/pages/speakers/{SanitySpeaker.slug__current}.js" /* webpackChunkName: "component---src-pages-speakers-sanity-speaker-slug-current-js" */),
  "component---src-pages-webinarjam-js": () => import("./../../../src/pages/webinarjam.js" /* webpackChunkName: "component---src-pages-webinarjam-js" */),
  "component---src-pages-weintour-tv-js": () => import("./../../../src/pages/weintour-tv.js" /* webpackChunkName: "component---src-pages-weintour-tv-js" */),
  "component---src-pages-weintyp-spiel-js": () => import("./../../../src/pages/weintyp-spiel.js" /* webpackChunkName: "component---src-pages-weintyp-spiel-js" */)
}

