/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check

import {
  Box,
  Button,
  Flex,
  Link,
  Text,
  Title,
  ChevronUpIcon,
  ChevronDownIcon,
} from '@bottlebooks/gatsby-theme-base';
import ShoppingCartButton from '@bottlebooks/gatsby-theme-event/src/components/Shop/ShoppingCartButton';
import { Plural } from '@lingui/macro';
import React from 'react';
import { jsx } from 'theme-ui';
import { Section } from '../../../cms-design-system';
import useSiteMetadata from '../../../useSiteMetadata';

/**
 * @typedef {"samstag-munich-2023" | "sonntag-munich-2023" } SessionId
 */

const sessions = [
  {
    sessionId: 'samstag-munich-2023',
    name: 'Samstag, den 25. März',
    price: '15.00',
  },
  {
    sessionId: 'sonntag-munich-2023',
    name: 'Sonntag, den 26. März',
    price: '15.00',
  },
];

export default function Tickets() {
  const [sessionId, setSessionId] = React.useState(
    /** @type {SessionId | null} */ (null)
  );

  function resetForm() {
    setSessionId(null);
  }
  return (
    <Section>
      <Section.Body sx={{ height: 1000 }}>
        <Box sx={{ border: 1, borderColor: 'border', padding: 3 }}>
          {!sessionId ? (
            <ChooseSession onClick={setSessionId} />
          ) : (
            <AddToBasket sessionId={sessionId} resetForm={resetForm} />
          )}
        </Box>
        <HiddenProducts />
      </Section.Body>
    </Section>
  );
}

function ChooseSession({ onClick }) {
  return (
    <Flex direction="column" gap={3} align="center">
      <Title variant="small">Tickets jetzt kaufen</Title>
      {sessions.map((session) => {
        if (session.disabled)
          return (
            <Button
              key={session.sessionId}
              variant="primary"
              sx={{
                backgroundColor: 'brand.lightSmoke',
                color: 'brand.graphite',
                ':hover': {
                  backgroundColor: 'brand.lightSmoke',
                  color: 'brand.graphite',
                },
              }}
              disabled
            >
              <Text>{session.name}</Text>
              <Text>(Ausverkauft!)</Text>
            </Button>
          );
        return (
          <Button
            key={session.sessionId}
            variant="primary"
            onClick={() => onClick(session.sessionId)}
          >
            {session.name}
          </Button>
        );
      })}
    </Flex>
  );
}

function AddToBasket({ sessionId, resetForm }) {
  // const [uniqueId, setUniqueId] = React.useState(Date.now());
  const { url } = useSiteMetadata();
  const [quantity, setQuantity] = React.useState(1);
  const [showDetailedQuanity, setShowDetailedQuanity] = React.useState(false);

  // Based on https://codesandbox.io/s/crimson-tree-7tffo?file=/index.html:1740-2103
  function addToCart(quantityOverride) {
    const session = sessions.find((session) => session.sessionId === sessionId);
    const product = {
      id: session?.sessionId,
      name: `Ticket für ${session?.name}`,
      description:
        'Bitte geben Sie die Kontaktdaten für das Ticket an. Für jede Person ist ein eigenes Ticket erforderlich. Wenn Sie weitere Tickets benötigen, klicken Sie auf "Weiter einkaufen" und wählen Sie die Anzahl der zusätzlichen Tickets aus, die Sie benötigen.',
      stackable: 'never',
      price: session?.price,
      url: `${url}/tickets`,
      shippable: false,
      // Used for discounts
      categories: ['HH'],
      hasTaxesIncluded: true,
      quantityStep: 0,
      customFields: [
        // Custom 1
        {
          name: 'itemGroup',
          value: 'ticket',
          type: 'hidden',
        },
        // Custom 2
        {
          name: 'Vorname',
          required: true,
          type: 'textbox',
        },
        // Custom 3
        {
          name: 'Nachname',
          required: true,
          type: 'textbox',
        },
        // Custom 4
        {
          name: 'Firma',
          type: 'textbox',
        },
        // Custom 5
        {
          name: 'Email-Adresse',
          required: true,
          type: 'textbox',
        },
        // Custom 6
        {
          name: 'Straße',
          required: true,
          type: 'textbox',
        },
        // Custom 7
        {
          name: 'Hausnummer',
          required: true,
          type: 'textbox',
        },
        // Custom 8
        {
          name: 'Postleitzahl',
          required: true,
          type: 'textbox',
        },
        // Custom 9
        {
          name: 'Stadt',
          required: true,
          type: 'textbox',
        },
        // Custom 10
        {
          name: 'Land',
          required: true,
          type: 'textbox',
        },
      ],
    };

    const itemCount = quantityOverride || quantity;
    for (var i = 0; i < itemCount; i++) {
      const productToAdd = {
        ...product,
        customFields: [
          ...product.customFields,
          // Generate a unique id so that each ticket can be identified
          // Custom 9
          {
            name: 'Unique Id',
            type: 'hidden',
            value: Math.floor(Math.random() * 10000),
          },
        ],
      };
      window.Snipcart.api.cart.items.add(productToAdd);
      // Open after adding to cart
      window.Snipcart.api.theme.cart.open();
    }
  }
  return (
    <Flex direction="column" gap={3} align="center">
      <Flex direction="column" align="center">
        <Title variant="small" sx={{ margin: 0, padding: 0 }}>
          Bestelle Karten für{' '}
          {sessions.find((session) => session.sessionId === sessionId)?.name}
        </Title>
        <Link onClick={resetForm} variant="icon">
          <Text>Eine andere Session auswählen</Text>
        </Link>
      </Flex>
      {!showDetailedQuanity ? (
        <React.Fragment>
          <Text>Ich bestelle</Text>
          <QuickPickButton quantity={1} onClick={addToCart} />
          <QuickPickButton quantity={2} onClick={addToCart} />
          <QuickPickButton quantity={3} onClick={addToCart} />
          <QuickPickButton quantity={4} onClick={addToCart} />
          <QuickPickButton quantity={5} onClick={addToCart} />
          <QuickPickButton quantity={6} onClick={addToCart} />
          <Button
            variant="secondard"
            onClick={() => {
              setQuantity(7);
              setShowDetailedQuanity(true);
            }}
          >
            Ich bestelle mehr als 6 Tickets
          </Button>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Flex align="center" gap={2}>
            <Text>Ich bestelle</Text>
            <Flex direction="column" align="center">
              <Button
                variant="secondary"
                onClick={() => setQuantity(quantity + 1)}
              >
                <ChevronUpIcon size="smallMedium" />
              </Button>
              <input
                id="quantity"
                type="number"
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
                min="1"
                sx={{
                  fontSize: 16,
                  padding: 2,
                  width: 80,
                  textAlign: 'center',
                  '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button':
                    {
                      '-webkit-appearance': 'none',
                      margin: 0,
                    },
                }}
              />

              <Button
                variant="secondary"
                onClick={() => {
                  if (quantity > 1) setQuantity(quantity - 1);
                }}
              >
                <ChevronDownIcon size="smallMedium" />
              </Button>
            </Flex>
            <Text>Tickets</Text>
          </Flex>
          <Button variant="primary" onClick={() => addToCart(quantity)}>
            In den Warenkorb
          </Button>
        </React.Fragment>
      )}
    </Flex>
  );
}

function QuickPickButton({ quantity, onClick }) {
  return (
    <Button variant="primary" onClick={() => onClick(quantity)}>
      <Plural
        value={quantity}
        one={<Text>{quantity} Ticket</Text>}
        other={<Text>{quantity} Tickets</Text>}
      ></Plural>
    </Button>
  );
}

/**
 * Snipcart checks that the products exist on the page during the checkout process
 */
function HiddenProducts() {
  return (
    <Box sx={{ display: 'none' }}>
      {sessions.map((session) => (
        <BuyTicket key={session.sessionId} ticketId={session.sessionId}>
          Add to cart
        </BuyTicket>
      ))}
    </Box>
  );
}

/**
 *
 * @param {object} props
 * @param {React.ReactNode} props.children
 * @param {string} props.ticketId
 * @returns
 */
function BuyTicket({ children, ticketId }) {
  const session = sessions.find((session) => session.sessionId === ticketId);

  const { url } = useSiteMetadata();
  return (
    <ShoppingCartButton
      data-item-id={ticketId}
      data-item-url={`${url}/tickets`}
      data-item-name={`Ticket ${ticketId}`}
      data-item-description='Bitte geben Sie die Kontaktdaten für das Ticket an. Für jede Person ist ein eigenes Ticket erforderlich. Wenn Sie weitere Tickets benötigen, klicken Sie auf "Weiter einkaufen" und wählen Sie die Anzahl der zusätzlichen Tickets aus, die Sie benötigen.'
      data-item-shippable="false"
      data-item-quantity-step="0"
      // Used for discounts
      data-item-categories="HH"
      data-item-custom1-name="itemGroup"
      data-item-custom1-type="hidden"
      data-item-custom1-value="ticket"
      data-item-custom2-name="Vorname"
      data-item-custom2-required="true"
      data-item-custom3-name="Nachname"
      data-item-custom3-required="true"
      data-item-custom4-name="Firma"
      data-item-custom4-required="false"
      data-item-custom5-name="Email-Adresse"
      data-item-custom5-required="true"
      data-item-custom6-name="Straße"
      data-item-custom6-required="true"
      data-item-custom7-name="Hausnummer"
      data-item-custom7-required="true"
      data-item-custom8-name="Postleitzahl"
      data-item-custom8-required="true"
      data-item-custom9-name="Stadt"
      data-item-custom9-required="true"
      data-item-custom10-name="Land"
      data-item-custom10-required="true"
      data-item-stackable="never"
      className={`snipcart-add-item`}
      data-item-custom11-name="Unique ID"
      data-item-custom11-type="hidden"
      data-item-price={session?.price}
      data-item-has-taxes-included="true"
      sx={{ display: 'none' }}
    >
      {children}
    </ShoppingCartButton>
  );
}
