/** @jsx jsx */
import {
  Box,
  Button,
  Col,
  Container,
  P,
  Row,
  SemiTitle,
} from '@bottlebooks/gatsby-theme-base';
import EventBanner from '@bottlebooks/gatsby-theme-event/src/components/Event/EventBanner';
import {
  ExhibitorListRow,
  ExhibitorsList,
} from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsList';
import EmptyState from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/EmptyState';
import SearchHeader from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/SearchHeader';
import useExhibitorSearch from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/useExhibitorSearch';
import createFacets from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/brandFacets';
import ExhibitorFacetsBar from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/ExhibitorFacetsBar';
import reducer, {
  initialState,
  searchTermChanged,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/exhibitorFilterReducer';
import ExhibitorFilters from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/ExhibitorFilters';
import ExhibitorSearchTermFacet from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/ExhibitorSearchTermFacet';
import * as sortOptions from '@bottlebooks/gatsby-theme-event/src/components/Filters/Brand/exhibitorSortOptions';
import FiltersToggleButton from '@bottlebooks/gatsby-theme-event/src/components/Filters/FiltersToggleButton';
import useFilter from '@bottlebooks/gatsby-theme-event/src/components/Filters/useFilter';
import useSyncedLocation, {
  getStateFromLocation,
} from '@bottlebooks/gatsby-theme-event/src/components/Filters/useSyncedLocation';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout';
import ListRow from '@bottlebooks/gatsby-theme-event/src/components/List/ListRow';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo.js';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import React from 'react';
import { jsx } from 'theme-ui';

const PAGE_SIZE = 30;

export default function ExhibitorsPage({
  exhibitors,
  event,
  location,
  facetConfig,
  locale,
  withEventBanner,
  withEventBannerOverlay,
}) {
  // Get the state.
  const stateFromLocation = getStateFromLocation(location, initialState);
  /** @type {[state: typeof initialState, dispatch: React.Dispatch<[event: string, payload?: any]>]} */
  const [state, dispatch] = React.useReducer(reducer, stateFromLocation);
  const link = useLink();
  // Change the URL hash when the filters change.
  useSyncedLocation(state, { initialState, location });
  // Filter the exhibitors by term and facets.
  const searched = useExhibitorSearch(exhibitors, state.searchTerm);
  const { items, facets } = useFilter(searched, {
    state,
    facetDefinitions: createFacets(facetConfig, state),
    sortOptions,
  });
  const [limit, setLimit] = React.useState(PAGE_SIZE);
  const [filtersHidden, setFiltersHidden] = React.useState(true);
  function dispatchFilter(event, isActive) {
    dispatch(event);
    setLimit(PAGE_SIZE);
    if (isActive) setFiltersHidden(true);
  }
  const limitedItems = items.slice(0, limit);
  const hasMore = items.length > limitedItems.length;
  const page = { path: location.href };
  return (
    <Layout locale={locale}>
      <SEO page={page} />
      {withEventBanner && (
        <EventBanner event={event} withOverlay={withEventBannerOverlay} />
      )}
      <Container
        fluid
        sx={{ paddingY: [3, 5], marginBottom: 4, maxWidth: 'container.fluid' }}
      >
        <SearchHeader totalCount={exhibitors.length} count={items.length} />
        <Button
          variant="outline"
          href="https://cdn.bottlebooks.me/54fdd71acbf8c2aeb644f35a/2023_03_24_6_09_52-DWI23_WTMUC_Katalog_web-3515.pdf"
          target="_blank"
          rel="noreferrer"
          sx={{
            display: ['inline-block', null, 'none'],
            marginTop: 2,
          }}
        >
          PDF-Katalog öffnen
        </Button>
      </Container>
      <Box sx={{ backgroundColor: 'light' }}>
        <Container fluid sx={{ maxWidth: 'container.fluid' }}>
          <ExhibitorFacetsBar
            dispatch={dispatch}
            state={state}
            sx={{ marginBottom: [4, 5] }}
          />
        </Container>
      </Box>
      <Container fluid sx={{ marginBottom: 6, maxWidth: 'container.fluid' }}>
        <Row>
          <Col sx={{ marginTop: 2, width: ['100%', null, `${500 / 12}%`] }}>
            <ExhibitorSearchTermFacet
              value={state.searchTerm}
              onChange={(value) => dispatch(searchTermChanged(value))}
            />
            <FiltersToggleButton
              onToggle={() => setFiltersHidden(!filtersHidden)}
              filtersHidden={filtersHidden}
              sx={{
                display: ['flex', null, 'none'],
                marginTop: -3,
                marginBottom: 3,
              }}
            />

            <ExhibitorFilters
              key={state.searchTerm}
              facets={facets}
              dispatch={dispatchFilter}
              state={state}
              totalCount={exhibitors.length}
              count={items.length}
              sx={{ display: [filtersHidden ? 'none' : null, null, 'block'] }}
            />
            <Box
              sx={{
                display: ['none', null, 'block'],
              }}
            >
              <SemiTitle>Sie möchten lieber offline lesen?</SemiTitle>
              <P>
                Laden Sie die PDF-Version des Katalogs mit allen Informationen,
                Verkostungen und Ausstellern herunter, die Sie auf dieser
                Website finden.
              </P>
              <Button
                variant="outline"
                href="https://cdn.bottlebooks.me/54fdd71acbf8c2aeb644f35a/2023_02_24_5_18_57-DWI23_WTMUC_Katalog_webOHNE-7066.pdf"
                target="_blank"
                rel="noreferrer"
              >
                PDF-Katalog öffnen
              </Button>
            </Box>
          </Col>
          <Col sx={{ width: ['100%', null, `${700 / 12}%`] }}>
            {!items || !items.length ? (
              <EmptyState />
            ) : (
              <ExhibitorsList sx={{ marginX: [-3, -2], marginY: -2 }}>
                {limitedItems.map((exhibitor) => (
                  <ExhibitorListRow
                    sx={{ marginX: [0, 2], marginY: [0, 2] }}
                    key={exhibitor.exhibitorId}
                    to={link.exhibitor(exhibitor)}
                    exhibitor={exhibitor}
                  />
                ))}
                {hasMore && (
                  <Button
                    as={ListRow}
                    variant="empty"
                    onClick={() => setLimit(limit + PAGE_SIZE)}
                    sx={{
                      margin: [0, 2],
                      paddingX: 4,
                      paddingY: 3,
                      textAlign: 'center',
                      display: 'block',
                      border: 0,

                      variant: 'text.small',
                    }}
                  >
                    <Trans>Show more</Trans>
                  </Button>
                )}
              </ExhibitorsList>
            )}
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}

export const fragment = graphql`
  fragment bb_ExhibitorsPage on Bottlebooks_Exhibitor {
    exhibitorId
    ...bb_ExhibitorFilters
    ...bb_ExhibitorListRow
    ...useLink_bb_Exhibitor
  }

  fragment bb_ExhibitorsPage_RegisteredBrand on Bottlebooks_RegisteredBrand {
    ...bb_ExhibitorFilters_RegisteredBrand
  }

  fragment ExhibitorsPage_first on Exhibitor {
    ...ExhibitorListRow_first
  }

  fragment ExhibitorsPage on Exhibitor {
    exhibitorId
    slug
    ...ExhibitorListRow
    ...Search_Exhibitor
    ...ExhibitorFilters
  }

  fragment ExhibitorsPage_Event on Event {
    ...EventBanner
  }
`;
