// @ts-check
/** @jsx jsx */
/** @jsxFrag React.Fragment */
import ExhibitorsPage from '@bottlebooks/gatsby-theme-event/src/components/ExhibitorsPage/ExhibitorsPage';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import React from 'react';
import SEO from '@bottlebooks/gatsby-theme-event/src/components/seo.js';

export default function ExhibitorsTemplate({
  data,
  pageContext,
  location,
  navigate,
}) {
  const { event, exhibitors } = afterQuery(data);
  return (
    <>
      <ExhibitorsPage
        {...pageContext}
        // Make sure the page is remounted on a different href (i.e. a different query).
        // QUESTION: Why is this actually necessary? Because of the page's state?
        key={location.href}
        event={event}
        exhibitors={exhibitors}
        facetConfig={{
          showRegionFilter: true,
          ...pageContext,
        }}
        location={location}
        navigate={navigate}
      />
      {/* The SEO component on the ExhibitorsPage sets the title with no way to override, so we need to set it again. */}
      <SEO
        page={{
          title: 'Aussteller | Virtuelle WeinTour - Wein - und Tourismusmesse',
        }}
      />
    </>
  );
}

function afterQuery({ allSanityExhibitor, event, site }) {
  const exhibitors = allSanityExhibitor.nodes.map((exhibitor) => {
    return {
      ...exhibitor,
      // This is required to render the flag
      countryCode: 'de',
      // This requires the ENV variable THEME_CONFIG='{"showRegionFilter": true}'
      regions: [exhibitor.region],
      // Should this be title or name in the GraphQL?
      stand: {
        title: `Stand ${exhibitor?.stand?.title} `,
      },
      // Create highlights
      highlights: {
        values: [
          ...exhibitor.highlights.map(translateOffering),
          exhibitor.offersDiscount && '10%-Aktion',
        ].filter(Boolean),
      },
    };
  });

  return {
    site,
    event,
    exhibitors,
  };
}

const offerings = {
  restaurant: 'Restaurant',
  hotel: 'Übernachtungsmöglichkeit',
  hiking: 'Wanderweg',
  rv_campsite: 'Wohnmobilstellplatz',
};

function translateOffering(offering) {
  return offerings[offering];
}

export const pageQuery = graphql`
  query ExhibitorsTemplate_WeinPlus {
    ...ExhibitorsTemplate_Query

    allSanityExhibitor {
      nodes {
        stand {
          title: name
        }
        ...sanity_ExhibitorHeader

        # country
        region

        mainImage {
          ...ImageWithPreview
        }
        # We need to rename this one to avoid the standard filters showing
        highlights: hospitalityOfferings
        tourismHighlights {
          type
        }
        offersDiscount
      }
    }
  }

  fragment sanity_ExhibitorHeader on SanityExhibitor {
    slug {
      current
    }
    name
    logo {
      ...ImageWithPreview
    }
  }

  fragment ExhibitorsTemplate_Query on Query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
