// @ts-check
import { graphql, useStaticQuery } from 'gatsby';

/**
 * @returns
 */
export default function useExhibitors() {
  const data = useStaticQuery(graphql`
    query useExhibitors {
      allSanityExhibitor {
        nodes {
          exhibitorId: _id
          ...sanity_ExhibitorHeader
        }
      }
    }
  `);
  const exhibitors = data.allSanityExhibitor.nodes;
  return exhibitors.map((exhibitor) => afterQuery(exhibitor));
}

function afterQuery(exhibitor) {
  if (!exhibitor) return undefined;
  return {
    ...exhibitor,
  };
}
const countryCodes = { Deutschland: 'de' };
