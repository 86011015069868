/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Flex,
  Link,
  Title,
  SemiTitle,
  Text,
} from '@bottlebooks/gatsby-design-system';
import { useLingui } from '@lingui/react';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import { jsx } from 'theme-ui';
import ProductSnipcartButton from '../@bottlebooks/gatsby-theme-event/components/Shop/ProductSnipcartButton';

export default function PackageListItem({ packageItem, ...rest }) {
  const { i18n } = useLingui();
  const product = {
    ...packageItem,
    productId: packageItem.slug.current,
    fullName: `Nr. ${packageItem.packageNumber}: ${packageItem.name}`,
    description: packageItem.description,
    shopImage: { fixed: { src: packageItem.imageUrl } },
    pricing: { price: packageItem.price },
  };
  return (
    <Box sx={{ position: 'relative' }} {...rest}>
      <Link
        to={`/packages/${packageItem.slug.current}`}
        sx={{
          display: 'block',
          border: 1,
          borderColor: 'border',
          transition: 'border',
          borderRadius: 'default',
          ':hover': { borderColor: 'primary' },
        }}
      >
        <Flex direction={['column', 'row']}>
          <Box sx={{ flexGrow: 0, flexShrink: 0 }}>
            {packageItem.packageImage && (
              <Image
                {...packageItem.packageImage}
                width={240}
                sizes="240px"
                alt={packageItem.name}
                sx={{ width: [180, null, null, 240], display: 'block' }}
                config={{ quality: 100 }}
              />
            )}
          </Box>
          <Flex
            direction="column"
            sx={{ marginLeft: [0, 3], padding: 2, flexGrow: 1 }}
          >
            <Text sx={{ color: 'text' }}>
              Online-Verkostung Nr. {packageItem.packageNumber}
            </Text>
            <Title variant="small">{packageItem.name}</Title>
            <Text sx={{ color: 'text' }}>
              {packageItem.subtitle}{' '}
              {/* {packageItem.speaker?.introduction} am{' '}
              {i18n.date(packageItem.webinarDate, { weekday: 'long' })}, den{' '}
              {i18n.date(packageItem.webinarDate, { dateStyle: 'medium' })} um{' '}
              {i18n.date(packageItem.webinarDate, { timeStyle: 'short' })} Uhr.{' '} */}
              <span sx={{ color: 'primary' }}>Mehr dazu…</span>
            </Text>
            {packageItem.products.length === 4 ? (
              <Text sx={{ color: 'text', flexGrow: 1 }}>
                Beinhaltet vier 50ml-Probierfläschchen.
              </Text>
            ) : (
              <Text sx={{ color: 'text', flexGrow: 1 }}>
                Beinhaltet drei 50ml-Probierfläschchen.
              </Text>
            )}
            <Flex align="stretch" direction={['column', null, null, 'row']}>
              <Box
                sx={{
                  color: 'text',
                  textAlign: 'right',
                  marginRight: 2,
                  flexGrow: 1,
                  marginBottom: [1, null, null, -1], // Adjust the position so the prices look more aligned with the buy button.
                }}
              >
                <Text sx={{ fontWeight: 'bold' }}>
                  {i18n.number(packageItem.price, {
                    style: 'currency',
                    currency: 'EUR',
                    minimumFractionDigits: 2,
                  })}
                </Text>
                <Text variant="smallest">inkl. MwSt. zzgl. Versandkosten</Text>
              </Box>
              {/* We reserve the space for the button and hide it */}
              <ProductSnipcartButton
                product={product}
                sx={{ visibility: 'hidden', flexShrink: 0 }}
                aria-hidden
              />
            </Flex>
          </Flex>
        </Flex>
      </Link>
      <Box sx={{ position: 'absolute', right: 2, bottom: 2 }}>
        <ProductSnipcartButton product={product} />
      </Box>
    </Box>
  );
}

export const fragment = graphql`
  fragment PackageListItem on SanityPackageItem {
    slug {
      current
    }
    packageNumber
    name
    subtitle
    webinarDate
    speaker {
      introduction
    }
    packageImage {
      ...ImageWithPreview
    }
    ...sanity_ProductSnipcartButton
  }
`;
