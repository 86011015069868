/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Box,
  Flex,
  Label,
  P,
  SemiTitle,
  Text,
  Stack,
} from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import Image from 'gatsby-plugin-sanity-image';
import Layout from '@bottlebooks/gatsby-theme-event/src/components/Layout/Layout';
import useLink from '@bottlebooks/gatsby-theme-event/src/useLink';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import ProductSnipcartButton from '../../@bottlebooks/gatsby-theme-event/components/Shop/ProductSnipcartButton';
import { RichText, Section } from '../../cms-design-system';
import dayjs from 'dayjs';
// Just have to load this
import 'dayjs/locale/de';
import WineListItem from '../../components/WineListItem';
import { useLingui } from '@lingui/react';
// Set globally
dayjs.locale('de');

export default function PackagePage({ data }) {
  const packageItem = afterQuery(data);
  if (!data) return null;
  return (
    <Layout
      page={{
        title: `Online-Verkostung Nr. ${packageItem.packageNumber} ${packageItem.name}`,
      }}
    >
      <PackageHeaderSection packageItem={packageItem} />
      <WinesSection packageItem={packageItem} />
      <SpeakerSection packageItem={packageItem} />
      <HowToSection packageItem={packageItem} variant="dark" />
    </Layout>
  );
}

function PackageHeaderSection({ packageItem, ...rest }) {
  const { i18n } = useLingui();
  return (
    <Section {...rest}>
      <Flex direction={['column', null, 'row']}>
        <Box sx={{ width: 200, flexGrow: 0, flexShrink: 0 }}>
          {packageItem.packageImage && (
            <Image
              {...packageItem.packageImage}
              width={200}
              sx={{ width: '100%' }}
              config={{ quality: 100 }}
            />
          )}
        </Box>
        <Box sx={{ marginLeft: [0, null, 4] }}>
          <Text>Online-Verkostung Nr. {packageItem.packageNumber}</Text>
          <Section.Title>{packageItem.name}</Section.Title>
          <Text>
            {packageItem.subtitle} {packageItem.speakerIntroduction}
            {/* am{' '}
            {i18n.date(packageItem.webinarDate, { weekday: 'long' })}, den{' '}
            {i18n.date(packageItem.webinarDate, { dateStyle: 'medium' })} um{' '}
            {i18n.date(packageItem.webinarDate, { timeStyle: 'short' })} Uhr. */}
          </Text>
          <Text sx={{ color: 'text', flexGrow: 1 }}>
            WineWalk als Online-Verkostung. Beinhaltet je drei bis vier
            50ml-Probierfläschchen.
          </Text>
          <SemiTitle>€9,90 inkl. MwSt. zzgl. Versandkosten</SemiTitle>
          <Box sx={{ marginY: 3 }}>
            <ProductSnipcartButton
              product={{
                ...packageItem,
                productId: packageItem._id,
                fullName: `Nr. ${packageItem.packageNumber}: ${packageItem.name}`,
                description: packageItem.description,
                shopImage: {
                  fixed: {
                    src: packageItem.imageUrl,
                  },
                },
              }}
            />
          </Box>
          <RichText>{packageItem.description}</RichText>
        </Box>
        <AtAGlance
          packageItem={packageItem}
          sx={{
            width: 300,
            flexShrink: 0,
            marginLeft: [0, null, 3],
            marginBottom: 3,
          }}
        />
      </Flex>
    </Section>
  );
}

function AtAGlance({ packageItem, ...rest }) {
  const { i18n } = useLingui();
  return (
    <Box {...rest}>
      <SemiTitle sx={{ marginTop: 0, paddingTop: 0 }}>
        Alle Daten auf einen Blick
      </SemiTitle>
      <Stack align="flex-start">
        <Box>
          <Label>Datum</Label>
          <Text>
            Ab 3. April unbegrenzt abrufbar
            {/* {i18n.date(packageItem.webinarDate, { weekday: 'long' })},{' '}
            {i18n.date(packageItem.webinarDate, { dateStyle: 'medium' })} */}
          </Text>
        </Box>
        {/* <Box>
          <Label>Uhrzeit</Label>
          <Text>
            {i18n.date(packageItem.webinarDate, { timeStyle: 'short' })}
          </Text>
        </Box> */}
        <Box>
          <Label>{packageItem.speaker?.title}</Label>
          <Text>{packageItem.speaker?.name}</Text>
        </Box>
        <Button
          variant="primary"
          href={packageItem.webinarJoinUrl}
          target="_blank"
        >
          Zur Terminauswahl
        </Button>
      </Stack>
    </Box>
  );
}

function WinesSection({ packageItem, ...rest }) {
  const link = useLink();
  return (
    <Section {...rest}>
      <Section.Title sx={{ marginBottom: 3 }}>
        Auf diese Probierfläschchen (50ml) von folgenden Weinen dürfen Sie sich
        freuen
      </Section.Title>
      <Section.Body>
        {packageItem.products.map((product) => {
          // Calculate name that conforms to German rules
          const { vintage, name, classification, designation } = product;
          const productName = `${vintage} | ${name}, ${[
            classification,
            designation,
          ]
            .filter(Boolean)
            .join(', ')}`;
          return (
            <WineListItem
              key={product?.productId}
              // Vintage is included in name
              product={{ ...product, vintage: null, shortName: productName }}
              to={link.exhibitor(product?.exhibitor)}
              sx={{ color: 'text' }}
            />
          );
        })}
        <Box sx={{ marginTop: 3 }}>
          <Text variant="small">
            Herkunft der Weine: Deutschland, Weine enthalten Sulfite, regulärer
            Flaschenpreis / Liter 13,30 Euro, Flascheninhalt der Originalflasche
            jeweils 0.75 Liter.
          </Text>
        </Box>
      </Section.Body>
    </Section>
  );
}

function SpeakerSection({ packageItem, ...rest }) {
  return (
    <Section {...rest}>
      <Section.Title sx={{ marginBottom: 3 }}>
        Verkostung {packageItem.speaker?.introduction}
      </Section.Title>
      <Section.Body>
        <Flex direction={['column', null, 'row']}>
          {packageItem.speaker?.profileImage && (
            <Image
              {...packageItem.speaker?.profileImage}
              width={340}
              height={340}
              sizes="(max-width: 640px) 340px, 340px"
              sx={{
                width: 340,
                height: 340,
                display: 'block',
                flexShrink: 0,
                marginBottom: 3,
                marginRight: [0, null, 3],
              }}
            />
          )}
          <Box>
            <RichText>{packageItem.speaker?.description}</RichText>
            {packageItem.speaker?.externalUrl && (
              <Button
                href={packageItem.speaker?.externalUrl}
                target="_blank"
                variant="outline"
                sx={{ border: 0, marginY: 3 }}
              >
                Mehr über {packageItem.speaker?.name}
              </Button>
            )}
          </Box>
        </Flex>
      </Section.Body>
    </Section>
  );
}

function HowToSection({ packageItem, ...rest }) {
  const { i18n } = useLingui();
  return (
    <Section {...rest}>
      <Section.Title sx={{ marginBottom: 3 }}>
        Onlineverkostung - wie funktioniert's
      </Section.Title>
      <Section.Body>
        <Flex direction={['column', null, 'row']} justify="space-between">
          <Box sx={{ flexShrink: 1, marginBottom: 3 }}>
            <P>
              Den Link für Ihre Onlineverkostung erhalten Sie sofort nach
              abgeschlossener Bestellung automatisch in einer separaten Email.
            </P>
            <P>
              Bei frühzeitiger Bestellung erhalten Sie Ihr Weinpaket mit den
              vier* Mini-Bottles mit je 50 ml- Verkostungsschlucken vor Beginn
              der Verkostungen. Der Versand erfolgt Anfang April 2023. Bei
              späterer Bestellung versenden wir in der Regel innerhalb 3 Tagen
              nach Bestelleingang.
            </P>
            <P>
              Sie können es sich dann zu Hause gemütlich machen und passend zu
              Ihrem gewünschten Termin einschalten! Die Verkostung dauert etwa
              30 Minuten. Der Moderator verkostet mit Ihnen die vier* Weine und
              stellt Ihnen die Weingüter vor. Zur Vorbereitung müssen Sie
              lediglich den Verkostungs-Link anklicken, die temperierten
              Verkostungsfläschchen (50 ml) und Gläser bereit stellen.
            </P>
            <P>
              *Keine WineSamples bei Schaumwein & Sekt, diese Pakete enthalten
              nur drei Probierfläschchen.
            </P>
            {/* <P>
              Den Link für Ihre Onlineverkostung erhalten Sie sofort nach
              abgeschlossener Bestellung automatisch in einer separaten Email.
            </P>
            <P>
              In der Woche vor der Verkostung erhalten Sie dann Ihr Weinpaket
              mit den vier bestellten Weinen und vier Mini-Bottles mit je 50 ml-
              Verkostungsschlucken*. So können Sie während der Verkostung nach
              Belieben die kleinen Verkostungsflaschen öffnen und die Weine zu
              anderer Gelegenheit genießen.
            </P>
            <P>
              Im März heißt es dann, zu Hause gemütlich machen und passend zum
              Verkostungstermin einschalten! Der Versand erfolgt unmittelbar zum
              Veranstaltungstermin, voraussichtlich eine Woche vor der
              offiziellen Verkostung am{' '}
              {i18n.date(packageItem.webinarDate, { dateStyle: 'long' })}.
            </P>

            <P>
              *Solange der Vorrat reicht. Keine WineSamples bei Schaumwein &
              Sekt.
            </P> */}
            {/* <Box
                sx={{ padding: 2, backgroundColor: 'primary', color: 'white' }}
              >
                <Text>
                  Die Zustellung erfolgt nach der Live-Veranstaltung. Die
                  Verkostung kann jedoch immer auch in der Aufzeichnung
                  erfolgen, die in der Regel eine Stunde nach Ausstrahlung über
                  den gleichen Link abgerufen werden kann.
                </Text>
              </Box> */}
          </Box>
        </Flex>
      </Section.Body>
    </Section>
  );
}

export const query = graphql`
  query ($id: String) {
    sanityPackageItem(id: { eq: $id }) {
      _id
      slug {
        current
      }
      name
      packageImage {
        ...ImageWithPreview
      }
      subtitle
      description: _rawDescription
      packageNumber
      price
      speaker {
        name
        title
        introduction
        description: _rawDescription
        externalUrl
        profileImage {
          ...ImageWithPreview
        }
      }
      webinarDate
      webinarId
      webinarSchedule
      webinarJoinUrl
      productStands
      products {
        ...WineListItem
        exhibitor {
          slug {
            current
          }
        }
      }
      ...sanity_ProductSnipcartButton
    }
  }
`;

function afterQuery(data) {
  if (!data?.sanityPackageItem) return null;
  return {
    ...data.sanityPackageItem,
    pricing: { price: data.sanityPackageItem.price },
  };
}
