/** @jsxRuntime classic */
/** @jsx jsx */
// @ts-check
import {
  Col,
  Flex,
  Link,
  SemiTitle,
  Stack,
  Title,
} from '@bottlebooks/gatsby-theme-base';
import BrandCertifications from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandCertifications';
import BrandContacts from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandContacts';
import BrandDescription from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandDescription';
import BaseDetailsSecton from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandDetailsSection';
import BrandFounded from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandFounded';
import BrandGallery from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandGallery';
import BrandRegionSection from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandRegionSection';
import BrandTourism from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandTourism';
import BrandVideos from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandVideos';
import BrandWinemaker from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandWinemaker';
import PresentedBrands from '@bottlebooks/gatsby-theme-event/src/components/Brand/PresentedBrands';
import ProductsList from '@bottlebooks/gatsby-theme-event/src/components/ProductsList/ProductsList';
import { Trans } from '@lingui/macro';
import { graphql } from 'gatsby';
import { jsx } from 'theme-ui';
import HikingIcon from '../../../../components/icons/HikingIcon';
import HotelIcon from '../../../../components/icons/HotelIcon';
import PromotionIcon from '../../../../components/icons/PromotionIcon';
import RestaurantIcon from '../../../../components/icons/RestaurantIcon';
import RVCampsiteIcon from '../../../../components/icons/RVCampsiteIcon';
import WineListItem from '../../../../components/WineListItem';
export * from '@bottlebooks/gatsby-theme-event/src/components/Brand/BrandDetailsSection';

export default function BrandDetailsSection({ brand, ...rest }) {
  const hasProducts = brand.products && brand.products.length !== 0;

  return (
    <Col flex {...rest}>
      <BrandDescription brand={brand} />
      <WeinTourHighlights brand={brand} />
      {hasProducts ? (
        <Col flex sx={{ padding: 0, marginY: 4 }}>
          <Title sx={{ marginBottom: 4 }}>
            <Trans>
              Diese Weine präsentieren wir Ihnen auf der WeinTour in München
            </Trans>
          </Title>

          {hasProducts && (
            <ProductsList>
              {brand.products.map((product) => (
                <WineListItem
                  key={product._id}
                  product={{
                    ...product,
                    // Ugh - hack!
                    bottleSize: 0.75,
                    bottleImage: null, // There is only one bottle image, so better render none at all for now.
                  }}
                  sx={{ marginX: [0, 2], marginY: [0, 2], color: 'text' }}
                  href={product.onlineShop}
                  openInNewTab
                />
              ))}
            </ProductsList>
          )}
        </Col>
      ) : null}
      <BrandCertifications product={brand} />
      <PresentedBrands brand={brand} />
      <BrandWinemaker brand={brand} />
      <BrandRegionSection brand={brand} />
      <BrandFounded brand={brand} />
      <BrandTourism brand={brand} />
      <BrandGallery brand={brand} />
      <BrandVideos brand={brand} />
      <BrandContacts brand={brand} />
    </Col>
  );
}

export const fragment = graphql`
  fragment sanity_BrandDetailsSections on SanityExhibitor {
    ...sanity_BrandDescription
    highlights: hospitalityOfferings
    offersDiscount
  }
`;

BrandDetailsSection.fragment = BaseDetailsSecton.fragment;

function WeinTourHighlights({ brand }) {
  return (
    <Stack>
      <SemiTitle>Highlights auf dem Weingut</SemiTitle>
      <Flex gap={3}>
        {brand.highlights?.includes('hiking') ? (
          <HikingIcon />
        ) : (
          <HikingIcon sx={{ color: 'lightestText' }} />
        )}
        {brand.highlights?.includes('hotel') ? (
          <HotelIcon />
        ) : (
          <HotelIcon sx={{ color: 'lightestText' }} />
        )}
        {brand.highlights?.includes('restaurant') ? (
          <RestaurantIcon />
        ) : (
          <RestaurantIcon sx={{ color: 'lightestText' }} />
        )}
        {brand.highlights?.includes('rv_campsite') ? (
          <RVCampsiteIcon />
        ) : (
          <RVCampsiteIcon sx={{ color: 'lightestText' }} />
        )}

        {brand.offersDiscount && (
          <Link href={brand.website} target="_blank">
            <PromotionIcon />
          </Link>
        )}
      </Flex>
    </Stack>
  );
}
