/** @jsxRuntime classic */
/** @jsx jsx */
/** @jsxFrag React.Fragment */
// @ts-check
// in your cookie banner
import { Flex, Link, Text } from '@bottlebooks/gatsby-design-system';
import { Button } from '@bottlebooks/gatsby-theme-base';
import { useLocation } from '@reach/router'; // this helps tracking the location
import { initializeAndTrack } from 'gatsby-plugin-gdpr-cookies';
import React from 'react';
import { jsx } from 'theme-ui';
import Cookies from 'universal-cookie';

const COOKIE_NAME = 'bottlebooks-online-directory-gdpr';

export default function CookieBanner({ ...rest }) {
  const location = useLocation();
  const cookies = new Cookies();
  const [isVisible, setIsVisible] = React.useState(!cookies.get(COOKIE_NAME));

  if (!isVisible) return null;
  return (
    <Flex
      direction={['column', 'row']}
      align="center"
      justify="space-between"
      sx={{
        padding: 4,
        backgroundColor: 'primary',
        position: 'relative',
        minHeight: [225, 0],
      }}
      {...rest}
    >
      <Text sx={{ color: 'white' }}>
        Diese Website verwendet Cookies, um die Benutzerfreundlichkeit zu
        verbessern und Ihnen ein Einkaufserlebnis zu ermöglichen.{' '}
        <Link
          to={'/datenschutz'}
          sx={{ color: 'white', textDecoration: 'underline' }}
        >
          Erfahren Sie mehr.
        </Link>
      </Text>
      <Flex
        direction={['column', 'row-reverse']}
        align="center"
        sx={{ width: [null, 250], flexShrink: 0 }}
      >
        <Button
          variant="outline"
          sx={{ backgroundColor: 'white', margin: 2 }}
          onClick={() => {
            cookies.set(COOKIE_NAME, true, {
              // Don't ask again this year
              maxAge: 3600 * 365,
            });
            cookies.set('gatsby-gdpr-google-analytics', true);
            // Close this window
            setIsVisible(false);
            initializeAndTrack(location);
          }}
        >
          Ich akzeptiere
        </Button>
        <Link
          onClick={() => {
            // Close this window
            setIsVisible(false);
            return cookies.set(COOKIE_NAME, true, {
              // Ask again after an hour
              maxAge: 3600,
            });
          }}
          sx={{ color: 'white', textDecoration: 'underline', margin: 2 }}
        >
          Ich lehne ab
        </Link>
      </Flex>
    </Flex>
  );
}
